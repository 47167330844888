<template>
  <Transition name="fade" mode="out-in">
    <div
      v-show="isActive"
      @click.self="handleOverlayClick"
      @keydown.enter.prevent=""
      class="modal-overlay tw-fixed tw-left-0 tw-top-0 tw-z-[50] tw-h-screen tw-w-full tw-cursor-pointer tw-place-content-center tw-overflow-y-auto tw-bg-black/70 tw-pb-12 tw-pt-[50px] lg:tw-grid lg:tw-pb-[70px] lg:tw-pt-[62px]"
    >
      <section
        class="tw-m-auto tw-max-h-[70vh] tw-w-[90%] tw-cursor-default tw-overflow-y-auto tw-rounded-lg tw-bg-white tw-p-5 md:tw-max-w-[95%] lg:tw-max-h-[80vh] lg:tw-w-full lg:tw-max-w-full lg:tw-p-6"
      >
        <slot></slot>
      </section>
    </div>
  </Transition>
</template>

<script setup lang="ts">
interface PropsType {
  isActive: boolean;
  disableOverlayClose?: boolean;
}

const props = withDefaults(defineProps<PropsType>(), {
  disableOverlayClose: false,
});

const emit = defineEmits(["close"]);

const closeModal = () => {
  emit("close");
};

const handleOverlayClick = () => {
  if (!props.disableOverlayClose) {
    closeModal();
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
